import {axiosData} from "@/store/api/axios";

const state = () => ({
  tickets: null || JSON.parse(localStorage.getItem('tickets')),
  ticket: null || JSON.parse(localStorage.getItem('ticket'))
});

const getters = {
  getTickets(state) {
    return state.tickets;
  },
  getTicket(state) {
    return state.ticket;
  },
};

const actions = {
  setTicket({ commit }, payload) {
    commit('SET_TICKET', payload);
    payload
        ? localStorage.setItem('ticket', JSON.stringify(payload))
        : localStorage.removeItem('ticket');
  },
  setTickets({ commit }, payload) {
    commit('SET_TICKETS', payload);
    payload
        ? localStorage.setItem('tickets', JSON.stringify(payload))
        : localStorage.removeItem('tickets');
  },
  async grabarPuntos(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cambiando Puntos');

    try {

      const data = await axiosData(
          true,
          'tickets/puntos',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabarCambioEstado(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cambiando Estado');

    try {

      const data = await axiosData(
          true,
          'tickets/cambiaestado',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabarFormularioInka2(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tickets/grabar/formulario/inka/2',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabarFormularioInka1(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tickets/grabar/formulario/inka',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async saveQrCode(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'tickets/qrcode',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('listas/setListas', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async grabarFormulario(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Grabando Formulario');
    try {
      const data = await axiosData(
          true,
          'tickets/grabar/formulario',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getTicketsEventos(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Tickets');
    try {
      const data = await axiosData(
          true,
          'actos/eventos/activos/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getTicketsLista(_,id){
    this.dispatch('ui/handleLoaderBtn', true);

    this.dispatch('ui/setTextLoading', 'Cargando Tickets');
    try {
      const data = await axiosData(
          true,
          'tickets/evento/todos/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getTicketsByEvent(_,id){
    this.dispatch('ui/handleLoaderBtn', true);

    this.dispatch('ui/setTextLoading', 'Cargando Tickets');
    try {
      const data = await axiosData(
          true,
          'tickets/evento/'+id,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);

      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getTicketsByPerson(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);

    this.dispatch('ui/setTextLoading', 'Cargando Tickets');
    try {
      let pagina = payload.page - 1;
      const data = await axiosData(
          true,
          'tickets/'+payload.id+'?page='+pagina+'&size=6',
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('tickets/setTickets', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }}
};

const mutations = {
  SET_TICKETS(state, payload) {
    state.tickets = payload;
    localStorage.setItem('tickets', JSON.stringify(payload));
  },
  SET_TICKET(state, payload) {
    state.istickets = payload;
    localStorage.setItem('ticket', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
